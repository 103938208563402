import { render, staticRenderFns } from "./BannerElections2023.vue?vue&type=template&id=627e7612&"
import script from "./BannerElections2023.vue?vue&type=script&lang=js&"
export * from "./BannerElections2023.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\LRGithub\\lr-statics\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('627e7612')) {
      api.createRecord('627e7612', component.options)
    } else {
      api.reload('627e7612', component.options)
    }
    module.hot.accept("./BannerElections2023.vue?vue&type=template&id=627e7612&", function () {
      api.rerender('627e7612', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lr/js/vue/components/banner-elections-2023/BannerElections2023.vue"
export default component.exports